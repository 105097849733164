/* eslint-disable no-plusplus */

import { ReactNode } from "react";

interface GeneratePaginationConfig {
  currentPage: number;
  lastPage: number;
  edgeButtonsCount?: number;
  renderer: (page: number | null) => ReactNode;
}

export function generatePagination({
  currentPage,
  lastPage,
  renderer,
  edgeButtonsCount = 1,
}: GeneratePaginationConfig) {
  const buttons: ReactNode[] = [];

  const minFirstPage = Math.max(1, currentPage - edgeButtonsCount);
  const maxLastPage = Math.min(lastPage, currentPage + edgeButtonsCount);

  // First page with optional dots
  if (minFirstPage > 2) {
    buttons.push(renderer(1), renderer(null));
  } else if (minFirstPage > 1) {
    buttons.push(renderer(1));
  }

  // Main pages
  for (let page = minFirstPage; page <= maxLastPage; page++) {
    buttons.push(renderer(page));
  }

  // Last page with optional dots
  if (maxLastPage < lastPage - 1) {
    buttons.push(renderer(null), renderer(lastPage));
  } else if (maxLastPage < lastPage) {
    buttons.push(renderer(lastPage));
  }

  return buttons;
}
