import React from "react";

import { Hero } from "components/hero";
import { WebHeroSection } from "resources/AudiotekaApi";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";

interface Props {
  section: WebHeroSection;
  title: string;
}

export function WebHero({
  section: {
    elements: [element],
  },
  title,
}: Props) {
  const shouldShowButton = !!(element.button_deeplink && element.button_label);

  return (
    <Hero backgroundUrl={element.background_image_url} bannerUrl={element.banner_image_url}>
      {title && <Hero.Header type="heading4">{title}</Hero.Header>}
      <Hero.Text type="heading2" as="p" color={element.title_color}>
        {element.title}
      </Hero.Text>
      <Hero.Text type="bodyBig" as="p" color={element.description_color}>
        {element.description}
      </Hero.Text>
      {shouldShowButton && (
        <Hero.Button
          bgColor={element.button_color}
          textColor={element.button_label_color}
          {...deeplinkTranslator(element.button_deeplink)}
        >
          {element.button_label}
        </Hero.Button>
      )}
    </Hero>
  );
}
