import React, { ComponentProps } from "react";
import classNames from "classnames";

import { Teaser } from "components/teaser";
import { ProductList } from "resources/AudiotekaApi";

import css from "./product-grid.module.scss";

interface Props extends Omit<ComponentProps<typeof Teaser>, "colorless" | "product"> {
  productList: ProductList;
  darkMode?: boolean;
  isLoading?: boolean;
}

export function ProductGrid({ productList, darkMode, isLoading, ...props }: Props) {
  return (
    <div className={css.productGrid}>
      {productList._embedded["app:product"].map((product) => (
        <Teaser key={product.id} colorless={darkMode} product={product} {...props} />
      ))}
      {isLoading && <div className={classNames(css.loading, { [css.loading__dark]: darkMode })} />}
    </div>
  );
}
