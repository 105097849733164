export const removeEmptyValues = (obj) =>
  Object.keys(obj).reduce(
    (mem, key) => ({
      ...mem,
      ...(obj[key] ? { [key]: obj[key] } : {}),
    }),
    {}
  );

export const cleanJSON = (json) => {
  const stringified = JSON.stringify(json, function replacer(key, value) {
    if (typeof value === "function" || typeof value === "undefined") {
      delete this[key];
    }

    return value;
  });

  return JSON.parse(stringified);
};
