import React, { ComponentProps } from "react";
import classNames from "classnames";

import { NewIcon } from "../NewIcon";
import { PaginationItem } from "./pagination-item";
import { generatePagination } from "./pagination-utils";
import css from "./pagination.module.scss";

interface Props {
  darkMode?: boolean;
  onChange?: ComponentProps<typeof PaginationItem>["onChange"];
  route?: ComponentProps<typeof PaginationItem>["route"];
  page: number;
  pages: number;
  className?: string;
}

export const Pagination = ({ darkMode, onChange, page, pages, route, className }: Props) => {
  if (pages < 2) {
    return null;
  }

  return (
    <div className={classNames(css.pagination, className, { [css.pagination__darkMode]: darkMode })}>
      {page > 1 && (
        <PaginationItem onChange={onChange} route={route} page={page - 1}>
          <NewIcon icon="chevron-left" width="16" height="16" />
        </PaginationItem>
      )}
      {generatePagination({
        currentPage: page,
        lastPage: pages,
        renderer: (pageNumber) =>
          pageNumber ? (
            <PaginationItem
              key={pageNumber}
              active={pageNumber === page}
              onChange={onChange}
              route={route}
              page={pageNumber}
            >
              {pageNumber}
            </PaginationItem>
          ) : (
            <PaginationItem>...</PaginationItem>
          ),
      })}
      {page < pages && (
        <PaginationItem onChange={onChange} route={route} page={page + 1}>
          <NewIcon icon="chevron-right" width="16" height="16" />
        </PaginationItem>
      )}
    </div>
  );
};
