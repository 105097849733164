import React from "react";

import { BannerCarousel } from "components/banner-carousel";
import { Paragraph } from "components/paragraph";
import { AssociatedScreen } from "resources/AudiotekaApi";

import { WebHero } from "./web-hero";

interface Props {
  sections: AssociatedScreen["_embedded"]["app:screen-section"];
  title: string;
  children: React.ReactNode;
}

export function Sections({ sections, title, children }: Props) {
  return sections.map((section, index) => {
    switch (section.type) {
      case "web-hero":
        return <WebHero key={section.id} section={section} title={index === 0 ? title : ""} />;
      case "paragraph":
        return <Paragraph key={section.id} section={section} />;
      case "banner-carousel":
        return <BannerCarousel key={section.id} section={section} />;
      case "placeholder":
        return section.elements[0].placeholder_reference === "content" ? (
          <React.Fragment key={section.id}>{children}</React.Fragment>
        ) : null;
      default:
        return null;
    }
  });
}
