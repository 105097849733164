import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import { useParams, useSearchParams } from "next/navigation";

import { removeEmptyValues } from "utils/object";
import type { Pathname } from "pathnames";

import { Link } from "../link";
import { ButtonPrimitive } from "../button/button-primitive";
import css from "./pagination-item.module.scss";

interface Props extends PropsWithChildren {
  active?: boolean;
  onChange?: (page: number) => void;
  route?: Pathname;
  page?: number;
}

export function PaginationItem({ active, onChange, route, page, children }: Props) {
  const searchParams = useSearchParams();
  const params = useParams();

  const classes = classNames(css.paginationItem, {
    [css.paginationItem__nonInteractive]: !onChange && !route,
    [css.paginationItem__active]: active,
  });

  if (route) {
    return (
      <Link
        className={classes}
        route={route}
        params={removeEmptyValues({
          ...params,
          ...Object.fromEntries(searchParams.entries()),
          page: page > 1 ? String(page) : undefined,
        })}
      >
        {children}
      </Link>
    );
  }

  if (onChange) {
    return (
      <ButtonPrimitive className={classes} onClick={() => onChange(page)}>
        {children}
      </ButtonPrimitive>
    );
  }

  return <span className={classes}>{children}</span>;
}
